import React from "react";

import SignupButton from './signup';
import LoginButton from './login';

import '../css/loginSignupPage.css';


function LoginSignupPage() {
    return (
        <div id="loginSignup-main">
            <div id="loginSignup-container">
                <h1>School Planner</h1>
                <SignupButton className="loginSignup-SignupBtn" />
                <span> or </span>
                <LoginButton className="loginSignup-LoginBtn" />
                </div>
        </div>
    )
}

export default LoginSignupPage;