import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import reportWebVitals from './reportWebVitals';
import WebRoutes from './Routes';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Auth0ProviderWithTokenStorage = ({ children }) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  // Store the access token in a cookie after successful authentication
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently()
        .then((accessToken) => {
          Cookies.set('access_token', accessToken, { expires: 1 }); // Set the cookie to expire in 1 day
        })
        .catch((error) => {
          console.error('Error fetching access token:', error);
        });
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return children;
};

root.render(
  // I was made aware that these keys are public thus I don't need to hide them in a .env file
  // I might come back later to hide them in a .env file if it makes it easier to manage
  <Auth0Provider
    domain="dev-2gs7cawylq704dz1.us.auth0.com"
    clientId="W64r8TVKEKddYT0XTTMiS9LMpxhTgJ4K"
    authorizationParams={{
      redirect_uri: `${window.location.origin}`
    }}
    useRefreshTokens={true}
    cacheLocation='localstorage'
  >
    <Auth0ProviderWithTokenStorage>
      <React.StrictMode>
        <Router>
          <WebRoutes />
        </Router>
        {/* <SchoolPlannerDashboard /> */}
      </React.StrictMode>
    </Auth0ProviderWithTokenStorage>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
