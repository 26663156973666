import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import NavigationBar from './navbar';

const Profile = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    
    useEffect(() => {
        document.title = "School Planner | Profile";
    }, []);

    if (isLoading) {
        return <div style={{display: "none"}}/>;
    }


    return (
        isAuthenticated ? (
            <div>
                <NavigationBar />
                <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p>
            </div>
        ) : (
            <div>
                <p>
                    You are not logged in! Please sign in to see your profile!
                </p>
            </div>
        )
    );
};

export default Profile;