import React from "react";
import Calendar from 'react-calendar';

const CalendarComponent = () => {
    // Custom function to add class name to weekday date tiles
    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            if (date.getDay() !== 0 && date.getDay() !== 6) {
                return 'isWeekday roundedPadding'; // Add 'weekday-day' class to weekdays (Monday-Friday)
            }
            return 'roundedPadding'; // Add 'roundedPadding' class to all other days
        }
        return null; // Return null for other dates to avoid additional classes
    };

    return (
        <div className="calendar-container">
            <h2>Calendar</h2>
            <Calendar tileClassName={tileClassName} />
        </div>
    )
};

export default CalendarComponent;