import React from "react";

const UpcomingEventsComponent = () => {
    const events = [
        { id: 1, title: 'School Assembly', date: '2023-07-24', location: 'School Auditorium' },
        { id: 2, title: 'Parent-Teacher Meeting', date: '2023-07-30', location: 'Classroom A or B, nobody knows.' },
        // Add more events as needed
    ];

    return (
        <div className="events-container">
            <h2>Upcoming Events</h2>
            <hr />
            <ul>
                {events.map(event => (
                    <li key={event.id}>{`${event.title} - Date: ${event.date} - Location: ${event.location}`}</li>
                ))}
            </ul>
        </div>
    )
};

export default UpcomingEventsComponent;