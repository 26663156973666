import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import ProfileIcon from './ProfileIcon';

function NavigationBar() {
    return (
        // <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
        <Navbar className="bg-body-tertiary" data-bs-theme="dark">
            <Container className='container-fluid'>
                <Navbar.Brand href='/'>School Planner</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" />
                    <Nav>
                        <ProfileIcon />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;