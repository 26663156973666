import React from "react";


const UpcomingTasksComponent = () => {
    const tasks = [
        { id: 1, title: 'Complete Math Homework', dueDate: '2023-07-25' },
        { id: 2, title: 'Read Chapter 5', dueDate: '2023-07-26' },
        // Add more tasks as needed
    ];

    return (
        <div className="tasks-container">
            <h2>Upcoming Tasks</h2>
            <hr />
            <ul>
                {tasks.map(task => (
                    <li key={task.id}>{`${task.title} - Due: ${task.dueDate}`}</li>
                ))}
            </ul>
        </div>
    )
};

export default UpcomingTasksComponent;