import React from 'react';
import Dashboard from './components/Dashboard';
import NavigationBar from './components/navbar';

import { useAuth0 } from "@auth0/auth0-react";
import LoginSignupPage from './components/loginSignupPage';

const App = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    return (
        isLoading ? (
            <div>Loading...</div>
        ) : (
            isAuthenticated ? (
                // if true
                <div>
                    <NavigationBar />
                    <Dashboard />
                </div>
            ) : (
                // if false
                <LoginSignupPage />
            )
        )
    );
};

export default App;