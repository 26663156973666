import React, { useEffect } from 'react';

import CalendarComponent from './Dashboard/Calendar';
import UpcomingTasksComponent from './Dashboard/UpcomingTasks';
import UpcomingEventsComponent from './Dashboard/UpcomingEvents';
import LoginSignupPage from './loginSignupPage';


import '../css/Calendar.css'; // copy of the CSS file from the 'react-calendar' package with modifications
import '../css/Dashboard.css'; // Import the CSS file for styling
// react-boostrap is imported in index.js


// Implement react-bootstrap grid system with dashboard
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useAuth0 } from '@auth0/auth0-react';



const Dashboard = () => {
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    document.title = 'School Planner | Dashboard'
  })

  return (
    isAuthenticated ? (
      // <div className="school-planner-dashboard">
        <Container>
          <Row className='d-flex'>
            <Col className='flex-fill col'>
              <CalendarComponent />
            </Col>
            <Col className='flex-fill' md={6}>
              <UpcomingTasksComponent />
            </Col>
            <Col className='flex-fill' md={6}>
              <UpcomingEventsComponent />
            </Col>
          </Row>
        </Container>
      // </div>

      // <div className="school-planner-dashboard">
      //  <CalendarComponent />
      //  <UpcomingTasksComponent />
      //  <UpcomingEventsComponent />
      // </div>
    ) : (
      <LoginSignupPage />
    )
  );
};

export default Dashboard;
