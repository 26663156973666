import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
// import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import '../css/profileIcon.css';

import SignupButton from "./signup";
import LoginButton from "./login";

const ProfileIcon = () => {
    const { user, isAuthenticated, isLoading, logout} = useAuth0();

    if (isLoading) {
        return <div />;
    }

    return (
        isAuthenticated ? (
            <div className="nav-item ml-auto pfpIconLoaded">
                <NavDropdown 
                    title={
                        <img src={user.picture} alt={user.name} width={32} height={32} className="profileIcon" />
                    } 
                    id="basic-nav-dropdown" 
                    className='ml-auto'
                    align={'end'}
                >
                    <NavDropdown.Item href={`${window.location.origin}/profile`}>View Profile</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={(event) => {
                        event.preventDefault();
                        logout({ logoutParams: { returnTo: window.location.origin } });
                    }}>
                        Sign Out
                    </NavDropdown.Item>
                </NavDropdown>
            </div>

            // <div className="nav-item ml-auto">
            //     <img src={user.picture} alt={user.name} width={32} height={32} className="profileIcon" />
            // </div>
        ) : (
            <div className="nav-item ml-auto">
                {/* <NavDropdown title="Account" id="basic-nav-dropdown" className='ml-auto'>
                    <NavDropdown.Item href="#action/3.3">Sign </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                        Separated link
                    </NavDropdown.Item>
                </NavDropdown> */}

                {/* <Nav.Link onClick={
                    (e) => {
                        e.preventDefault();
                        loginWithRedirect();
                    }
                }>Signup/Login</Nav.Link> */}

                {/* <LoginButton /><span> or </span><SignupButton /> */}
            </div>
        )
    );
};

export default ProfileIcon;