import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from 'react-bootstrap/Button';

// const LoginButton2 = () => {
//   const { loginWithRedirect } = useAuth0();

//   return <button onClick={() => loginWithRedirect()}>Log In</button>;
// };

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();

  return (
      <Button className={props.className} variant="outline-light" onClick={() => loginWithRedirect()}>Log In</Button>
  )
};

export default LoginButton;