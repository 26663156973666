import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from 'react-bootstrap/Button';

// const SignupButton2 = () => {
//   const { loginWithRedirect } = useAuth0();

//   return <button onClick={() => loginWithRedirect({
//     authorizationParams: {
//         screen_hint: "signup"
//     }
//   })}>Sign Up</button>;
// };

const SignupButton = (props) => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button className={props.className} variant="primary" onClick={() => loginWithRedirect({
            authorizationParams: {
                screen_hint: "signup"
            }
        })}>Sign Up</Button>
    )
};

export default SignupButton;