import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import DemoApp from './demo/App';
import Profile from './components/Profile';
import App from './App';
import LoginSignupPage from './components/loginSignupPage';
import PageNotFound from './components/PageNotFound';

const WebRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<App />} />
            {/* <Route path="/test" element={<Dashboard />} /> */}
            {/* <Route path="/original-app" element={<DemoApp />} /> */}
            <Route path="/profile" element={<Profile />} />
            {/* <Route path="/login-signup" element={<LoginSignupPage />} /> */}
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
};

export default WebRoutes;