import React, { useEffect } from "react";
import '../css/PageNotFound.css';
import NavigationBar from "./navbar";

const PageNotFound = () => {
    // dynamically change the title of the page
    useEffect(() => {
        document.title = "School Planner | 404 Not Found";
    }, []);


    return (
        <div>
            <NavigationBar />
            <div className="dflex justifyCenter">
                <div className="centerText dflex wh-50vh" id="errorBx">
                    <h1 className="justifyCenter">404 Not Found.</h1>
                    <h2 className="justifyCenter">Oops, this page doesn't exist. Are you sure you're at the right place?</h2>
                </div>
            </div>
        </div>
    )
};

export default PageNotFound;